/* colours
------------------------------------------------------ */
.no-fixed-width {
  max-width: 100%; }

.donation-form-heading {
  font-size: 3.75rem;
  color: #001489;
  font-weight: bold;
  margin-bottom: 15px; }

.donation-sub-heading {
  font-size: 1.3125rem;
  margin-bottom: 30px;
  margin-top: 15px; }

.donation-step-1 .donation-choices-container, .step-1 .donation-choices-container {
  max-width: 400px;
  margin: 0 auto; }

.donation-choices-container {
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: center;
  padding: 30px 15px;
  width: 100%; }
  .donation-choices-container span.donation-input {
    display: flex;
    align-content: center;
    justify-content: center; }
    .donation-choices-container span.donation-input.donation-input-key-donate_in_memory_of {
      max-width: 100%;
      width: 100%;
      flex-direction: row-reverse;
      align-items: flex-start; }
      .donation-choices-container span.donation-input.donation-input-key-donate_in_memory_of input:checked + label {
        color: #777;
        background: none;
        border: none;
        cursor: pointer; }
      .donation-choices-container span.donation-input.donation-input-key-donate_in_memory_of label {
        border: none;
        padding: 0;
        margin-left: 0;
        padding-left: 3px;
        position: relative;
        top: 2px;
        font-size: 1rem;
        width: auto;
        color: #0c2340; }
    .donation-choices-container span.donation-input.donation-input-key-donation_purposes label {
      text-align: center;
      padding: 12px 40px; }
    .donation-choices-container span.donation-input input {
      display: none;
      color: #001489; }
      .donation-choices-container span.donation-input input:checked + label {
        border: 1px solid #001489;
        color: #fff;
        background-color: #001489; }
      .donation-choices-container span.donation-input input + label {
        color: #001489; }
      .donation-choices-container span.donation-input input[name="donate_in_memory_of"] {
        display: block;
        height: 20px;
        width: 20px; }
    .donation-choices-container span.donation-input label {
      border: 1px solid #001489;
      padding: 12px 0;
      text-align: center;
      color: #001489;
      height: 46px;
      width: 100%;
      position: relative;
      font-size: 1rem;
      cursor: pointer; }
    .donation-choices-container span.donation-input:first-of-type label {
      border-radius: 30px 0 0 30px; }
    .donation-choices-container span.donation-input:last-of-type label {
      border-radius: 0px 30px 30px 0; }

.marlin-donation-tile {
  margin: 20px;
  cursor: pointer;
  overflow: hidden; }

.donation-submit.btn-donate-now button {
  display: flex;
  align-content: center;
  justify-content: center;
  font-size: 1rem;
  float: none;
  padding: 18px 15px;
  border-radius: 38px;
  font-weight: bold;
  background: #00c298;
  position: relative;
  max-width: 303px;
  width: 100%;
  display: block;
  margin: auto;
  text-align: center;
  color: #001489;
  border: 1px solid #00c298;
  outline: none;
  cursor: pointer; }

.donation-submit.donation-submit-next button {
  font-size: 1rem;
  float: none;
  padding: 18px 0px;
  border-radius: 38px;
  font-weight: bold;
  background: #fff;
  position: relative;
  max-width: 303px;
  width: 100%;
  display: block;
  margin: 2rem auto 0;
  text-align: center;
  color: #001489;
  border: 1px solid #001489;
  outline: none;
  cursor: pointer; }

.donation-submit.donation-submit-back {
  background: none;
  border: none;
  text-align: center; }
  .donation-submit.donation-submit-back button {
    background: none;
    border: none;
    color: #001489;
    margin-top: 20px;
    font-weight: bold;
    font-size: 0.8125rem;
    outline: none;
    cursor: pointer; }

.donation-submit.donation-submit-text {
  background: none;
  border: none;
  text-align: center; }
  .donation-submit.donation-submit-text button {
    background: none;
    border: none;
    color: #001489;
    margin-top: 20px;
    font-weight: bold;
    font-size: 0.8rem;
    outline: none;
    cursor: pointer; }

.donation-submit-submit button:visited {
  background: #74d1ea;
  color: #0c2340; }

.donation-submit-submit button:hover {
  background: #74d1ea;
  color: #0c2340; }

.donation-submit-submit button:active {
  background: #74d1ea;
  color: #0c2340; }

.donation-submit-submit button:focus {
  background: #74d1ea;
  color: #0c2340; }

.donation-input-address_autocomplete span {
  color: #ff0000;
  font-size: 0.8rem; }
  .donation-input-address_autocomplete span.donation-input input {
    margin-bottom: 0; }
  .donation-input-address_autocomplete span.donation-input select {
    width: 100%;
    margin-bottom: 0;
    margin-top: 2rem;
    height: 60px;
    padding: 0 16px;
    font-size: 1.25rem;
    background: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='%238C98F2'><polygon points='0,0 100,0 50,50'/></svg>") no-repeat;
    background-size: 12px;
    background-position: calc(100% - 20px) center;
    background-repeat: no-repeat;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none; }
    .donation-input-address_autocomplete span.donation-input select + label {
      display: none; }
    .donation-input-address_autocomplete span.donation-input select option {
      color: #fff;
      background-color: #0c2340; }

.leafcutter-donation-tile:hover .wp-block-cover__inner-container:after, .leafcutter-donation-tile:hover .wp-block-cover__inner-container:before {
  background: #001489; }

.leafcutter-donation-tile.active .wp-block-cover__inner-container:after, .leafcutter-donation-tile.active .wp-block-cover__inner-container:before {
  background: #001489; }

.leafcutter-donation-tile:visited .wp-block-cover__inner-container:after, .leafcutter-donation-tile:visited .wp-block-cover__inner-container:before {
  background: #001489; }

.leafcutter-donation-tile .wp-block-cover {
  margin: 0;
  pointer-events: none;
  user-select: none; }
  .leafcutter-donation-tile .wp-block-cover::before {
    display: none !important; }

.wp-block-cover__inner-container {
  border-radius: 50%;
  top: -25vw;
  right: 35%;
  z-index: 2;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out; }
  .wp-block-cover__inner-container:after {
    content: "";
    position: absolute;
    width: 150%;
    height: 100%;
    border-radius: 100%;
    top: 50%;
    left: -25%;
    right: 0;
    border-radius: 100%;
    top: 250px;
    right: 0;
    z-index: 2;
    background: #0c2340;
    opacity: 1;
    -webkit-transition: all 0.2s ease-in-out;
    -moz-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out; }
  .wp-block-cover__inner-container:before {
    content: "";
    position: absolute;
    width: 150%;
    height: 100%;
    border-radius: 100%;
    top: 50%;
    left: -25%;
    right: 0;
    opacity: 0.35;
    top: 225px;
    z-index: 2;
    background: #0c2340;
    -webkit-transition: all 0.2s ease-in-out;
    -moz-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out; }

.input-form-control-half {
  max-width: 49%;
  float: left;
  width: 100%; }
  .input-form-control-half.donation-input-contact_honouree_last_name {
    float: right; }
  .input-form-control-half.donation-input-contact_last_name {
    float: right; }
  @media only screen and (max-width: 640px) {
    .input-form-control-half {
      max-width: 100%;
      float: none; } }

.payment_select {
  margin-top: 10px; }

.donation-input {
  width: 100%;
  position: relative;
  margin-bottom: 0;
  text-align: center;
  color: #0c2340;
  display: flex;
  flex-flow: column; }
  .donation-input.is-invalid input {
    margin-bottom: 5px; }
  .donation-input input {
    order: 2;
    transition: all 0.2s ease-in-out;
    touch-action: manipulation;
    height: 66px;
    border: 1px solid #CFD2D4;
    border-radius: 0;
    z-index: 15;
    background: transparent;
    width: 100%;
    font-size: 1.25rem;
    padding: 0 15px; }
    .donation-input input::placeholder {
      opacity: 0;
      transition: inherit;
      color: transparent; }
  .donation-input textarea {
    background-color: transparent;
    font-size: 0.75rem;
    color: #777;
    padding: 20px;
    min-height: 250px;
    color: #0c2340;
    font-size: 1.25rem; }
    .donation-input textarea:focus ~ label {
      top: 8px;
      bottom: 10px;
      left: 20px;
      font-size: 0.875rem;
      opacity: 1; }
    .donation-input textarea::placeholder {
      opacity: 1;
      font-size: 1.25rem;
      color: #0c2340; }
  .donation-input label {
    order: 1;
    text-align: left;
    font-size: 1.25rem;
    padding: var(--input-padding-y) var(--input-padding-x);
    display: block;
    margin-bottom: 0;
    line-height: 1;
    color: #0c2340;
    transition: all 0.2s ease-in-out;
    touch-action: manipulation;
    z-index: 10; }
  .donation-input .touched span {
    color: #ff0000; }
  .donation-input span {
    font-size: 0.8rem;
    order: 3; }

.donation-step-2 .donation-input.input-form-control input:placeholder-shown + label, .donation-step-3 .donation-input.input-form-control input:placeholder-shown + label, .donation-step-4 .donation-input.input-form-control input:placeholder-shown + label {
  cursor: text;
  max-width: 66.66%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  transform-origin: center;
  transform: translate(1rem, 2.8rem) scale(1);
  transform-origin: top left; }

.donation-step-2 .donation-input.input-form-control input:not(:placeholder-shown) + label,
.donation-step-2 .donation-input.input-form-control input:focus + label, .donation-step-3 .donation-input.input-form-control input:not(:placeholder-shown) + label,
.donation-step-3 .donation-input.input-form-control input:focus + label, .donation-step-4 .donation-input.input-form-control input:not(:placeholder-shown) + label,
.donation-step-4 .donation-input.input-form-control input:focus + label {
  transform: translate(1rem, 1.5rem) scale(0.8);
  cursor: pointer;
  max-width: 100%;
  transform-origin: top left; }

.donation-plugin-form-step-contents-container.step-1 {
  max-width: 1340px;
  width: 100%;
  margin: 0 auto; }

.donation-plugin-form-step-contents-container.donation-step-2 {
  max-width: 720px;
  width: 100%;
  margin: 0 auto; }

.donation-plugin-form-step-contents-container.donation-step-3 {
  max-width: 720px;
  width: 100%;
  margin: 0 auto; }

.donation-plugin-form-step-contents-container.donation-step-4 {
  max-width: 720px;
  width: 100%;
  margin: 0 auto; }
  .donation-plugin-form-step-contents-container.donation-step-4 .donation-input.donation-input-amount {
    max-width: 315px;
    width: 100%;
    margin: 0 auto; }
    .donation-plugin-form-step-contents-container.donation-step-4 .donation-input.donation-input-amount .donation-amount-input-currency-sign {
      position: absolute;
      top: 50%;
      -webkit-transform: translateY(-50%);
      transform: translateY(-50%);
      -ms-transform: translateY(-50%);
      -moz-transform: translateY(-50%);
      color: #000;
      font-size: 1.875rem;
      left: 5px; }
    .donation-plugin-form-step-contents-container.donation-step-4 .donation-input.donation-input-amount .donation-amount-input-currency-code {
      position: absolute;
      top: 50%;
      -webkit-transform: translateY(-50%);
      transform: translateY(-50%);
      -ms-transform: translateY(-50%);
      -moz-transform: translateY(-50%);
      right: 15px;
      text-transform: uppercase;
      color: #000;
      font-size: 0.8125rem; }
  .donation-plugin-form-step-contents-container.donation-step-4 .donation-input input[name="donation_amount"] {
    padding-left: 40px;
    min-width: 1px; }

.step-1 .donation-input.donation-input-amount {
  max-width: 567px;
  height: 119px;
  width: 100%;
  margin: 2rem auto 30px;
  position: relative;
  text-align: center;
  border: 1px solid #CFD2D4;
  padding: 15px 10px 0; }
  .step-1 .donation-input.donation-input-amount .donation-amount-input-currency-sign {
    position: absolute;
    margin: 29px 20px;
    color: #0c2340;
    font-size: 1.875rem;
    font-weight: 700; }
  .step-1 .donation-input.donation-input-amount .donation-amount-input-currency-code {
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    right: 15px;
    text-transform: uppercase;
    color: #0c2340;
    font-size: 0.8125rem;
    font-weight: 400; }
  .step-1 .donation-input.donation-input-amount input {
    padding: 0 45px;
    font-size: 1.875rem;
    color: #0c2340;
    font-weight: 700;
    border: none;
    outline: none;
    border-radius: 0;
    min-width: 1px; }
  .step-1 .donation-input.donation-input-amount label {
    font-weight: 300;
    font-size: 1rem; }
  .step-1 .donation-input.donation-input-amount .error {
    position: absolute;
    bottom: 0;
    left: 5px; }

.step-1 .donation-submit.btn-donate-now button {
  display: flex;
  align-content: center;
  justify-content: center;
  font-size: 1rem;
  float: none;
  padding: 18px 15px;
  border-radius: 38px;
  font-weight: bold;
  background: #00c298;
  position: relative;
  max-width: 303px;
  width: 100%;
  display: block;
  margin: auto;
  text-align: center;
  color: #001489;
  border: 1px solid #00c298;
  outline: none; }

.donation-payment-container .payment-component {
  margin-bottom: 20px; }

.donation-payment-container .payment-option-container {
  display: flex;
  align-content: center;
  justify-content: center;
  padding: 0 0 30px;
  width: 100%; }
  .donation-payment-container .payment-option-container .payment-option {
    width: 100%; }
    .donation-payment-container .payment-option-container .payment-option input {
      display: none;
      color: #001489; }
      .donation-payment-container .payment-option-container .payment-option input:checked + label {
        border: 1px solid #001489;
        color: #fff;
        background-color: #001489; }
    .donation-payment-container .payment-option-container .payment-option label {
      border: 1px solid #001489;
      padding: 12px 0;
      color: #001489;
      height: 50px;
      position: relative;
      font-size: 0.8rem;
      width: 100%;
      display: block;
      text-align: center;
      font-size: 1rem;
      text-transform: capitalize; }
    .donation-payment-container .payment-option-container .payment-option:only-child label {
      border-radius: 30px; }
    .donation-payment-container .payment-option-container .payment-option:first-child:not(:only-child) label {
      border-radius: 30px 0 0 30px; }
    .donation-payment-container .payment-option-container .payment-option:last-child:not(:only-child) label {
      border-radius: 0 30px 30px 0; }

.donation-plugin-form-contents-container .error {
  color: #ff0000;
  float: left;
  text-align: left;
  font-size: 1rem; }

.donation-plugin-form-contents-container select {
  color: #666;
  width: 100%;
  margin-top: 0;
  margin-bottom: 30px;
  height: 66px;
  padding: 0 16px;
  font-size: 1.25rem; }
  .donation-plugin-form-contents-container select + label {
    display: none; }

.donation-plugin-form-contents-container .payment_select select {
  order: 2; }
  .donation-plugin-form-contents-container .payment_select select + label {
    display: block;
    order: 1;
    margin-bottom: 5px;
    font-size: 1rem; }

.donation-plugin-form-contents-container .donation-payment-container {
  position: relative; }
  .donation-plugin-form-contents-container .donation-payment-container .payment-option .error {
    position: absolute;
    bottom: -5px;
    left: 0;
    right: 0;
    text-align: center;
    float: none; }

.donation-plugin-form-contents-container .tile-heading {
  position: absolute;
  z-index: 999999;
  top: 50%;
  left: 0;
  right: 0;
  transform: translateY(-50%);
  margin: 50px auto 0; }

.donation-plugin-form-contents-container .tile-paragraph {
  position: absolute;
  z-index: 999999;
  text-align: center;
  width: 100%;
  top: 50%;
  left: 0;
  right: 0;
  transform: translateY(-50%);
  margin: 120px auto 0;
  font-size: 1rem;
  line-height: 1.5;
  padding: 0 15px; }

.donation-plugin-form-contents-container .StripeElement {
  width: 100%;
  position: relative;
  margin-bottom: 0;
  text-align: center;
  margin-top: 10px;
  border: 1px solid #ccc;
  border-radius: 3px;
  padding: 20px 21px;
  height: 60px; }
  .donation-plugin-form-contents-container .StripeElement .__PrivateStripeElement {
    top: 5px;
    left: -6px; }

.donation-plugin-form-contents-container .donation-submit-submit.btn-donate-now {
  margin-top: 2rem; }

.donation-plugin-form-contents-container .donation-input span {
  color: #ff0000;
  float: left;
  text-align: left;
  font-size: 1rem; }

#append_donation_form .address_not_found a {
  color: #fff; }

#append_donation_form .donation-plugin-form-errored-container {
  color: #ff5454;
  border-radius: 26px;
  border: 1px solid #0c2340; }

#append_donation_form .donation-plugin-form-app-container {
  background-color: #0c2340;
  padding: 10px 30px 30px; }
  #append_donation_form .donation-plugin-form-app-container.white-enabled {
    background-color: #fff !important; }
    #append_donation_form .donation-plugin-form-app-container.white-enabled * {
      color: #0c2340 !important;
      border-color: #0c2340 !important; }
    #append_donation_form .donation-plugin-form-app-container.white-enabled select option {
      background-color: transparent; }
    #append_donation_form .donation-plugin-form-app-container.white-enabled .error {
      color: #ff0000 !important; }
    #append_donation_form .donation-plugin-form-app-container.white-enabled .donation-choices-container span.donation-input input:checked + label {
      color: #fff !important;
      background-color: #0c2340 !important; }
    #append_donation_form .donation-plugin-form-app-container.white-enabled div:nth-last-child(2).donation-choices-container input:checked + label {
      background-color: transparent !important;
      color: #0c2340 !important; }
    #append_donation_form .donation-plugin-form-app-container.white-enabled .leafcutter-donation-tile .tile-heading {
      color: #FFFFFF !important; }
    #append_donation_form .donation-plugin-form-app-container.white-enabled .leafcutter-donation-tile:hover .wp-block-cover__inner-container:after, #append_donation_form .donation-plugin-form-app-container.white-enabled .leafcutter-donation-tile:hover .wp-block-cover__inner-container:before {
      background-color: #0c2340; }
    #append_donation_form .donation-plugin-form-app-container.white-enabled .leafcutter-donation-tile.active .wp-block-cover__inner-container:after, #append_donation_form .donation-plugin-form-app-container.white-enabled .leafcutter-donation-tile.active .wp-block-cover__inner-container:before {
      background-color: #0c2340; }
    #append_donation_form .donation-plugin-form-app-container.white-enabled .leafcutter-donation-tile:visited .wp-block-cover__inner-container:after, #append_donation_form .donation-plugin-form-app-container.white-enabled .leafcutter-donation-tile:visited .wp-block-cover__inner-container:before {
      background-color: #0c2340; }
    #append_donation_form .donation-plugin-form-app-container.white-enabled .donation-submit.btn-donate-now button {
      border: 0 !important; }
    #append_donation_form .donation-plugin-form-app-container.white-enabled .donation-input span.error {
      color: #ff0000 !important; }
    #append_donation_form .donation-plugin-form-app-container.white-enabled .payment-option-container .payment-option input:checked + label {
      background-color: #0c2340 !important;
      color: #FFFFFF !important; }
    #append_donation_form .donation-plugin-form-app-container.white-enabled .payment-component input {
      background-color: #FFFFFF !important; }
    #append_donation_form .donation-plugin-form-app-container.white-enabled .payment-component .StripeElement {
      background-color: #FFFFFF !important; }
  #append_donation_form .donation-plugin-form-app-container .donation-step-1 div.donation-choices-container, #append_donation_form .donation-plugin-form-app-container .step-1 div.donation-choices-container {
    padding: 30px 0;
    max-width: none;
    width: 100%;
    align-items: stretch;
    justify-content: flex-start;
    margin: 0; }
    #append_donation_form .donation-plugin-form-app-container .donation-step-1 div.donation-choices-container .donation-input-key-donation_type,
    #append_donation_form .donation-plugin-form-app-container .donation-step-1 div.donation-choices-container span.donation-input, #append_donation_form .donation-plugin-form-app-container .step-1 div.donation-choices-container .donation-input-key-donation_type,
    #append_donation_form .donation-plugin-form-app-container .step-1 div.donation-choices-container span.donation-input {
      max-width: 185px; }
      #append_donation_form .donation-plugin-form-app-container .donation-step-1 div.donation-choices-container .donation-input-key-donation_type label,
      #append_donation_form .donation-plugin-form-app-container .donation-step-1 div.donation-choices-container span.donation-input label, #append_donation_form .donation-plugin-form-app-container .step-1 div.donation-choices-container .donation-input-key-donation_type label,
      #append_donation_form .donation-plugin-form-app-container .step-1 div.donation-choices-container span.donation-input label {
        color: #FFFFFF;
        background-color: transparent;
        border: 1px solid #fff; }
      #append_donation_form .donation-plugin-form-app-container .donation-step-1 div.donation-choices-container .donation-input-key-donation_type input:checked + label,
      #append_donation_form .donation-plugin-form-app-container .donation-step-1 div.donation-choices-container span.donation-input input:checked + label, #append_donation_form .donation-plugin-form-app-container .step-1 div.donation-choices-container .donation-input-key-donation_type input:checked + label,
      #append_donation_form .donation-plugin-form-app-container .step-1 div.donation-choices-container span.donation-input input:checked + label {
        background-color: #FFFFFF;
        color: #0C2340; }
  #append_donation_form .donation-plugin-form-app-container div.donation-choices-container .donation-input-key-donation_type label,
  #append_donation_form .donation-plugin-form-app-container div.donation-choices-container span.donation-input label {
    color: #FFFFFF;
    background-color: transparent;
    border: 1px solid #fff; }
  #append_donation_form .donation-plugin-form-app-container div.donation-choices-container .donation-input-key-donation_type input:checked + label,
  #append_donation_form .donation-plugin-form-app-container div.donation-choices-container span.donation-input input:checked + label {
    background-color: #FFFFFF;
    color: #0C2340; }
  @media only screen and (max-width: 640px) {
    #append_donation_form .donation-plugin-form-app-container .step-2 .donation-choices-container {
      flex-direction: column; }
      #append_donation_form .donation-plugin-form-app-container .step-2 .donation-choices-container label {
        border-radius: 25px;
        margin-bottom: 10px; } }
  #append_donation_form .donation-plugin-form-app-container .leafcutter-donation-tile {
    margin: 0 0 15px 0; }
    @media only screen and (min-width: 768px) {
      #append_donation_form .donation-plugin-form-app-container .leafcutter-donation-tile {
        margin: 0 15px 15px 0; } }
    #append_donation_form .donation-plugin-form-app-container .leafcutter-donation-tile .wp-block-cover__inner-container:before {
      top: 0;
      border-radius: 0;
      width: 100%;
      left: 0; }
    #append_donation_form .donation-plugin-form-app-container .leafcutter-donation-tile .wp-block-cover__inner-container::after {
      content: '';
      display: none; }
    #append_donation_form .donation-plugin-form-app-container .leafcutter-donation-tile .wp-block-cover__inner-container .tile-heading {
      margin: 0;
      font-size: 2.0625rem;
      font-weight: bold; }
    #append_donation_form .donation-plugin-form-app-container .leafcutter-donation-tile.active .wp-block-cover__inner-container:before {
      background-color: #fff;
      opacity: 1; }
    #append_donation_form .donation-plugin-form-app-container .leafcutter-donation-tile.active .tile-heading {
      color: #0C2340; }
  #append_donation_form .donation-plugin-form-app-container .donation-input label {
    color: #fff;
    width: 100%; }
  #append_donation_form .donation-plugin-form-app-container .payment-option-container .payment-option label {
    cursor: pointer; }
  #append_donation_form .donation-plugin-form-app-container .step-1 .donation-input.donation-input-amount {
    margin-right: 1rem;
    float: left;
    max-width: 350px;
    padding-top: 0;
    margin-top: 0;
    display: flex;
    height: auto;
    width: 100%;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center; }
    #append_donation_form .donation-plugin-form-app-container .step-1 .donation-input.donation-input-amount .donation-amount-input-currency-sign {
      color: #fff;
      order: 2;
      flex: 0 0 12%;
      position: static;
      margin: 0; }
    #append_donation_form .donation-plugin-form-app-container .step-1 .donation-input.donation-input-amount input {
      color: #fff;
      order: 3;
      flex: 0 0 70%;
      padding: 0;
      min-width: 1px; }
    #append_donation_form .donation-plugin-form-app-container .step-1 .donation-input.donation-input-amount .donation-amount-input-currency-code {
      color: #fff;
      right: 30px;
      order: 4;
      flex: 0 0 5%;
      position: static;
      transform: translate(0%, 0%);
      top: 0%; }
    #append_donation_form .donation-plugin-form-app-container .step-1 .donation-input.donation-input-amount label {
      order: 1; }
    #append_donation_form .donation-plugin-form-app-container .step-1 .donation-input.donation-input-amount .error {
      order: 5;
      flex: 0 0 100%;
      display: block;
      width: 100%;
      position: static; }
  #append_donation_form .donation-plugin-form-app-container .step-1 div:nth-last-child(2).donation-choices-container {
    float: left;
    width: auto;
    margin-bottom: 15px;
    padding-bottom: 0;
    padding-top: 0; }
    #append_donation_form .donation-plugin-form-app-container .step-1 div:nth-last-child(2).donation-choices-container .donation-input-key-donate_in_memory_of {
      max-width: 100%; }
      #append_donation_form .donation-plugin-form-app-container .step-1 div:nth-last-child(2).donation-choices-container .donation-input-key-donate_in_memory_of label {
        height: auto;
        top: 0;
        border: 0;
        text-align: left;
        margin-left: 0.2rem;
        transform: translateY(10%); }
      #append_donation_form .donation-plugin-form-app-container .step-1 div:nth-last-child(2).donation-choices-container .donation-input-key-donate_in_memory_of input:checked + label {
        background-color: transparent;
        color: #FFFFFF;
        border-radius: 0; }
  #append_donation_form .donation-plugin-form-app-container .step-1 .donation-submit {
    display: inline-block;
    padding: 15px 0px 10px;
    width: 100%; }
    #append_donation_form .donation-plugin-form-app-container .step-1 .donation-submit button {
      float: left;
      padding: 18px 0px;
      max-width: 350px; }
  #append_donation_form .donation-plugin-form-app-container .donation-submit-back button {
    color: #fff; }
  #append_donation_form .donation-plugin-form-app-container input[type="text"],
  #append_donation_form .donation-plugin-form-app-container input[type="email"],
  #append_donation_form .donation-plugin-form-app-container input[type="url"],
  #append_donation_form .donation-plugin-form-app-container input[type="password"],
  #append_donation_form .donation-plugin-form-app-container input[type="search"],
  #append_donation_form .donation-plugin-form-app-container input[type="number"],
  #append_donation_form .donation-plugin-form-app-container input[type="tel"],
  #append_donation_form .donation-plugin-form-app-container input[type="range"],
  #append_donation_form .donation-plugin-form-app-container input[type="date"],
  #append_donation_form .donation-plugin-form-app-container input[type="month"],
  #append_donation_form .donation-plugin-form-app-container input[type="week"],
  #append_donation_form .donation-plugin-form-app-container input[type="time"],
  #append_donation_form .donation-plugin-form-app-container input[type="datetime"],
  #append_donation_form .donation-plugin-form-app-container input[type="datetime-local"],
  #append_donation_form .donation-plugin-form-app-container input[type="color"],
  #append_donation_form .donation-plugin-form-app-container textarea {
    color: #FFFFFF; }
  #append_donation_form .donation-plugin-form-app-container .donation-input-address_autocomplete select {
    background-color: transparent;
    color: #fff; }
  #append_donation_form .donation-plugin-form-app-container .payment-option-container .payment-option label {
    border: 1px solid #FFF;
    color: #FFF;
    background-color: transparent; }
  #append_donation_form .donation-plugin-form-app-container .payment-option-container .payment-option input:checked + label {
    border: 1px solid #FFF;
    color: #001489;
    background-color: #FFF; }
  #append_donation_form .donation-plugin-form-app-container .payment-component label {
    z-index: 999;
    color: #0C2340; }
  #append_donation_form .donation-plugin-form-app-container .payment-component input,
  #append_donation_form .donation-plugin-form-app-container .payment-component .StripeElement {
    background-color: #FFFFFF;
    color: #0C2340; }
  #append_donation_form .donation-plugin-form-app-container .donation-step-4 .donation-input.donation-input-amount .donation-amount-input-currency-sign,
  #append_donation_form .donation-plugin-form-app-container .donation-step-4 .donation-input.donation-input-amount .donation-amount-input-currency-code {
    color: #FFFFFF; }

/*+-----------------------------------+
| Media Query
+-----------------------------------+*/
@media only screen and (max-width: 991px) {
  .donation-tiles-content-container {
    --tile-column-count: 2fr !important;
    --tile-column-grid-cols-value: 2fr 2fr !important; } }

@media only screen and (max-width: 767px) {
  .donation-form-heading {
    font-size: 1.9rem; }
  .donation-sub-heading {
    font-size: 1rem; }
  .donation-tiles-content-container {
    --tile-column-count: 1fr !important;
    --tile-column-grid-cols-value: 1fr !important; }
  .donation-choices-container span label {
    left: 0; }
  .step-1 .donation-input.donation-input-amount .donation-amount-input-currency-code {
    right: 30px; }
  .donation-plugin-form-step-contents-container.donation-step-1, .donation-plugin-form-step-contents-container.step-1 {
    padding: 0 15px; }
  .donation-plugin-form-step-contents-container.donation-step-2 {
    padding: 0 15px; }
  .donation-plugin-form-step-contents-container.donation-step-3 {
    padding: 0 15px; }
  .donation-plugin-form-step-contents-container.donation-step-4 {
    padding: 0 15px; }
  .donation-choices-container span.donation-input.donation-input-key-donation_purposes label {
    padding: 15px 5px;
    font-size: 0.85rem; }
  .donation-payment-container .payment-option-container label {
    padding: 15px 25px; }
  .donation-plugin-form-step-contents-container.donation-step-4 .donation-input.donation-input-amount .donation-amount-input-currency-sign {
    margin-left: 15px; }
  .donation-plugin-form-step-contents-container.donation-step-4 .donation-input input[name="donation_amount"] {
    margin-left: 0; } }

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 2) {
  .tile-heading {
    font-size: 1.6rem; }
  .tile-paragraph {
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    right: 15px;
    margin: 100px 0; } }

@media screen and (min-width: 769px) and (max-width: 1120px) {
  .tile-heading {
    font-size: 1.6rem; } }

.donation-check label {
  margin-left: 0.2rem;
  border-radius: 0; }

.donation-plugin-form-processing-container {
  text-align: center; }
